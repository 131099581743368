import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.actionPlaceHolder = this.sidebarActionElement
    $(this.actionPlaceHolder).children('i').addClass(this.data.get('icon'))
    $(this.actionPlaceHolder).children('#sidebar-text').text(this.data.get('title'))
    this.actionPlaceHolder.addEventListener('click', this.toggleSidebar.bind(this))
  }

  disconnect () {
    this.actionPlaceHolder.removeEventListener('click', this.toggleSidebar)
  }

  toggleSidebar () {
    this.element.classList.toggle('active')
  }

  get sidebarActionElement () {
    return document.getElementById('sidebar-action')
  }
}
