import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'calendar',
    'input'
  ]

  changeColor () {
    this.inputTarget.focus()
    this.inputTarget.click()
  }

  handleColorChange () {
    const color = this.inputTarget.value
    const calendar = this.calendarTarget
    const calendarId = calendar.dataset.id

    calendar.style.backgroundColor = color
    calendar.style.color = color
    calendar.dataset.color = color

    $.ajax({
      url: '/calendars/' + calendarId,
      type: 'PUT',
      data: { calendar: { color } },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    const colorChangedEvent = new CustomEvent('color-changed', {
      detail: { color, calendarId }
    })

    this.element.dispatchEvent(colorChangedEvent)
  }
}
