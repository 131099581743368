import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'element'
  ]

  resize (event) {
    if (event.target.open) {
      $(window).trigger('resize')
    }
  }
}
