import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'translate', 'x', 'check']

  toggle () {
    this.toggleButton()
    this.toggleX()
    this.toggleTranslate()
    this.toggleCheck()
  }

  toggleButton () {
    this.buttonTarget.classList.toggle('bg-blue-600')
    this.buttonTarget.classList.toggle('bg-gray-200')
  }

  toggleTranslate () {
    this.translateTarget.classList.toggle('translate-x-5')
    this.translateTarget.classList.toggle('translate-x-0')
  }

  toggleX () {
    this.xTarget.classList.toggle('opacity-0')
    this.xTarget.classList.toggle('ease-out')
    this.xTarget.classList.toggle('duration-100')
    this.xTarget.classList.toggle('opacity-100')
    this.xTarget.classList.toggle('ease-in')
    this.xTarget.classList.toggle('duration-200')
  }

  toggleCheck () {
    this.checkTarget.classList.toggle('opacity-100')
    this.checkTarget.classList.toggle('ease-in')
    this.checkTarget.classList.toggle('duration-200')
    this.checkTarget.classList.toggle('opacity-0')
    this.checkTarget.classList.toggle('ease-out')
    this.checkTarget.classList.toggle('duration-100')
  }
}
