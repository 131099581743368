import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import intersection from 'lodash/intersection'

export default class extends Controller {
  static targets = [
    'autoForm',
    'autoFormWrapper',
    'cave',
    'caveContainer',
    'chosenDrug',
    'customIndication',
    'drugIdInput',
    'indication',
    'manualForm',
    'submit',
    'suggestions'
  ]

  initialize () {
    this.queryDrug = debounce(this.queryDrug, 200)
  }

  connect () {
    const hasForm = this.hasDrugIdInputTarget
    if (!hasForm) return

    const fillType = this.data.get('fill-type')
    if (fillType === 'auto' && this.hasAutoFormTarget) {
      this.autoFormTarget.classList.remove('hidden')
    } else if (this.hasManualFormTarget) {
      this.manualFormTarget.classList.remove('hidden')
    }
  }

  queryDrug (event) {
    const query = event.target.value
    this.chosenDrugTarget.innerHTML = ''
    if (query === '') {
      this.suggestionsTarget.innerHTML = ''
      return
    }

    $.get('/medicine/drugs', { query }, (html) => {
      this.suggestionsTarget.innerHTML = html
    })
  }

  chooseDrug (event) {
    event.preventDefault()
    const drugId = $(event.target).data('drug-id')
    const drugIngredients = $(event.target).data('drug-ingredient').split(', ')

    this.drugIdInputTarget.value = drugId
    this.suggestionsTarget.innerHTML = ''
    this.updateCaveWarnings(drugIngredients)

    $.get(`/medicine/drugs/${drugId}`, { cave: this.data.get('cave') }, (html) => {
      this.chosenDrugTarget.innerHTML = html
    })
  }

  updateIndication (event) {
    const isCustom = $(event.target).find(':selected').data('custom')

    if (isCustom) {
      this.customIndicationTarget.classList.remove('hidden')
    } else {
      this.customIndicationTarget.classList.add('hidden')
    }
  }

  switchToAuto () {
    this.autoFormTarget.classList.remove('hidden')
    this.manualFormTarget.classList.add('hidden')
  }

  switchToManual () {
    this.autoFormTarget.classList.add('hidden')
    this.manualFormTarget.classList.remove('hidden')
  }

  updateCaveWarnings (drugIngredients) {
    if (!this.hasCaveContainerTarget) {
      return
    }

    this._updateSubmitButton(drugIngredients)
    this._displayConflictingCave(drugIngredients)
  }

  _updateSubmitButton (drugIngredients) {
    if (this._allConflictingIngredients(drugIngredients).length > 0) {
      this.caveContainerTarget.classList.remove('hidden')
      const confirmMessage = $(this.submitTarget).data('optional-confirm')
      $(this.submitTarget).attr('data-confirm', confirmMessage)
    } else {
      this.caveContainerTarget.classList.add('hidden')
      $(this.submitTarget).data('confirm', '')
    }
  }

  _displayConflictingCave (drugIngredients) {
    this.caveTargets.forEach((caveTarget) => {
      const caveDrugIngredients = $(caveTarget).data('ingredient').split(', ').filter(Boolean)
      const conflictingIngredients = intersection(caveDrugIngredients, drugIngredients)

      if (conflictingIngredients.length > 0) {
        caveTarget.classList.remove('hidden')
        $(caveTarget).find('.conflictingIngredients').html(conflictingIngredients.join(', '))
      } else {
        caveTarget.classList.add('hidden')
      }
    })
  }

  _allConflictingIngredients (drugIngredients) {
    const allCaveIngredients = this.caveTargets.flatMap((caveTarget) => $(caveTarget).data('ingredient').split(', ').filter(Boolean))

    return intersection(drugIngredients, allCaveIngredients)
  }
}
