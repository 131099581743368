import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    showSpinner: Boolean,
    url: String,
    method: String
  }

  request () {
    fetch(this.urlValue, { method: this.methodValue, headers: this.headers() })
  }

  headers () {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }
}
