import { Controller } from '@hotwired/stimulus'
import { transitionIn, transitionOut } from '../shared/bare_transitions'

const connectedToggleControllers = new Set()

document.addEventListener('turbo:before-cache', () => {
  connectedToggleControllers.forEach(controller => {
    controller.hideWithoutAnimation()
  })
})

export default class extends Controller {
  static targets = [
    'toggleArea'
  ]

  initialize () {
    this.hiddenClass = 'hidden'
  }

  connect () {
    connectedToggleControllers.add(this)
  }

  diconnect () {
    connectedToggleControllers.delete(this)
  }

  toggle () {
    this.toggleAreaTargets.forEach((target) => {
      if (this.targetIsHidden(target)) {
        this.showWithAnimation(target)
      } else {
        this.hideWithAnimation(target)
      }
    })
  }

  hide (event) {
    const escaped = event.keyCode === 27
    const outsideClicked = event.type === 'click' && !this.element.contains(event.target)

    if (escaped || outsideClicked) {
      if (!this.toggleAreaIsHidden()) {
        this.hideWithAnimation(this.toggleAreaTarget)
      }
    }
  }

  showWithAnimation (target) {
    transitionIn(target, () => target.classList.remove(this.hiddenClass))
  }

  hideWithAnimation (target) {
    transitionOut(target, () => target.classList.add(this.hiddenClass))
  }

  hideWithoutAnimation () {
    if (this.hasToggleAreaTarget) {
      this.toggleAreaTarget.classList.add(this.hiddenClass)
    }
  }

  toggleAreaIsHidden () {
    return this.toggleAreaTarget.classList.contains(this.hiddenClass)
  }

  targetIsHidden (target) {
    return target.classList.contains(this.hiddenClass)
  }
}
