import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const inputs = [
      ...this.element.querySelectorAll('input[type="password"]')
    ]
    inputs.forEach(el => {
      el.setAttribute('readonly', 'readonly')
      el.setAttribute('value', '')
      el.setAttribute('onfocus', 'this.removeAttribute("readonly")')
    })
  }
}
