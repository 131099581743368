import { Controller } from '@hotwired/stimulus'
import { transitionIn } from '../shared/bare_transitions'

export default class extends Controller {
  connect () {
    transitionIn(this.element)
    setTimeout(() => { this.remove() }, 3500)
  }

  remove () {
    this.element.remove()
  }
}
