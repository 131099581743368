import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'countValue']

  connect () {
    this.maxValue = this.data.get('max-value') || '140'
    this.update()
  }

  update () {
    const count = this.inputTarget.value.length
    const remainingChars = this.maxValue - count

    this.countValueTarget.textContent = remainingChars
  }
}
