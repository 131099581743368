import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'relationTypeExisting',
    'relationTypeCreate',
    'existing',
    'create'
  ]

  showCreate () {
    const relType = this.relationTypeExistingTarget.value
    this.relationTypeCreateTarget.value = relType

    this.existingTarget.classList.add('hidden')
    this.createTarget.classList.remove('hidden')
  }

  showExisting () {
    const relType = this.relationTypeCreateTarget.value
    this.relationTypeExistingTarget.value = relType

    this.createTarget.classList.add('hidden')
    this.existingTarget.classList.remove('hidden')
  }
}
