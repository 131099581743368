import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'recurrentEvery',
    'weekdays',
    'weekdaysCheckbox',
    'allDayCheckbox',
    'datetimeSelector',
    'timeSelector'
  ]

  connect () {
    // recurentEventTarget is only present in the first event in the recurring event series
    if (this.hasRecurrentEveryTarget) {
      if (this.recurrentEveryTarget.value === 'week') {
        this.enableWeekdays()
      } else {
        this.disableWeekdays()
      }
    }
    this.allDayChange()
  }

  recurrentEveryChange () {
    if (this.recurrentEveryTarget.value === 'week') {
      this.enableWeekdays()
    } else {
      this.disableWeekdays()
    }
  }

  enableWeekdays () {
    this.weekdaysTarget.classList.remove('hidden')
    this.weekdaysCheckboxTargets.forEach((checkbox, index) => {
      checkbox.disabled = false
    })
  }

  disableWeekdays () {
    this.weekdaysTarget.classList.add('hidden')
    this.weekdaysCheckboxTargets.forEach((checkbox, index) => {
      checkbox.disabled = true
    })
  }

  allDayChange () {
    if (!this.hasAllDayCheckboxTarget) return

    if (this.allDayCheckboxTarget.checked) {
      this.timeSelectorTargets.forEach((timeSelector, index) => {
        timeSelector.classList.add('hidden')
        timeSelector.disabled = true
      })
    } else {
      this.timeSelectorTargets.forEach((timeSelector, index) => {
        timeSelector.classList.remove('hidden')
        timeSelector.disabled = false
      })
    }
  }

  startDateSync () {
    if (!this.needsSync()) { return }
    this.startYearSelect().value = this.endYearSelect().value
    this.startMonthSelect().value = this.endMonthSelect().value
    this.startDaySelect().value = this.endDaySelect().value
    this.startHourSelect().value = this.endHourSelect().value
    this.startMinuteSelect().value = this.endMinuteSelect().value
  }

  endDateSync () {
    if (!this.needsSync()) { return }
    this.endYearSelect().value = this.startYearSelect().value
    this.endMonthSelect().value = this.startMonthSelect().value
    this.endDaySelect().value = this.startDaySelect().value
    this.endHourSelect().value = this.startHourSelect().value
    this.endMinuteSelect().value = this.startMinuteSelect().value
  }

  needsSync () {
    return this.datetimeGreaterThan(this.startDatetime(), this.endDatetime())
  }

  startDatetime () {
    return [
      parseInt(this.startYearSelect().value),
      parseInt(this.startMonthSelect().value),
      parseInt(this.startDaySelect().value),
      parseInt(this.startHourSelect().value),
      parseInt(this.startMinuteSelect().value)
    ]
  }

  endDatetime () {
    return [
      parseInt(this.endYearSelect().value),
      parseInt(this.endMonthSelect().value),
      parseInt(this.endDaySelect().value),
      parseInt(this.endHourSelect().value),
      parseInt(this.endMinuteSelect().value)
    ]
  }

  startMinuteSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[start(5i)]"]') }
  startHourSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[start(4i)]"]') }
  startDaySelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[start(3i)]"]') }
  startMonthSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[start(2i)]"]') }
  startYearSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[start(1i)]"]') }
  endMinuteSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[end(5i)]"]') }
  endHourSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[end(4i)]"]') }
  endDaySelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[end(3i)]"]') }
  endMonthSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[end(2i)]"]') }
  endYearSelect () { return this.datetimeSelectorTarget.querySelector('select[name="event[end(1i)]"]') }

  datetimeGreaterThan (a, b) {
    for (const index in a) {
      const aElem = a[index]
      const bElem = b[index]
      if (aElem > bElem) {
        return true
      } else if (aElem < bElem) {
        return false
      }
    }

    return false
  }
}
