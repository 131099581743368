import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'
import { format } from 'date-fns'

export default class extends Controller {
  static values = {
    unit: String,
    plotline: Number
  }

  connect () {
    this.chartData = JSON.parse(this.element.dataset.chartData)
    this.initializeTooltips()
    if (this.hasPlotlineValue) this.drawPlotline()
    this.chart = new Chart(this.element, { type: 'line', ...this.chartData })
  }

  disconnect () {
    this.chart.destroy()
  }

  initializeTooltips () {
    this.chartData.options.plugins.tooltip = {
      callbacks: {
        label: (context) => `${context.raw.y} ${this.unitValue || ''}`,
        title: (items) => format(items[0].parsed.x, 'dd-MM-yyyy')
      }
    }
  }

  drawPlotline () {
    const beforeDatasetsDraw = (chart, arg, options) => {
      const { ctx, chartArea: { left, width }, scales: { y } } = chart
      ctx.save()

      ctx.strokeStyle = 'rgb(72, 187, 120)'
      ctx.strokeRect(left, y.getPixelForValue(this.plotlineValue), width, 1)
      ctx.restore()
    }

    this.chartData.plugins = [{ id: 'plotline', beforeDatasetsDraw }]
  }
}
