import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  async loadMore (e) {
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
    const res = await $.get(this.urlValue)
    $(this.element).replaceWith(res)
  }
}
