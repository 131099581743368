import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['row', 'day', 'startDate', 'endDate']

  connect () {
    this.updateRows()
  }

  updateRows () {
    const startDateString = this.startDateTarget.value
    const endDateString = this.endDateTarget.value

    if (startDateString && endDateString) {
      const [startDay, startMonth, startYear] = startDateString.split('-').map(x => parseInt(x))
      const [endDay, endMonth, endYear] = endDateString.split('-').map(x => parseInt(x))
      const endDate = new Date(endYear, endMonth - 1, endDay)

      this.dayTargets.forEach((dayTarget, i) => {
        const date = new Date(startYear, startMonth - 1, startDay + i)
        // Label the rows
        dayTarget.innerHTML = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`

        if (date <= endDate) {
          this.rowTargets[i].classList.remove('hidden')
        } else {
          this.rowTargets[i].classList.add('hidden')
        }
      })
    } else {
      this.rowTargets.forEach((row) => {
        row.classList.add('hidden')
      })
    }
  }

  dayName (number) {
    return this.weekdays[number]
  }
}
