import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'element']

  connect () {
    this.update()
  }

  update () {
    this.hideAllElements()
    this.showElementForSelectedId()
  }

  hideAllElements () {
    this.elementTargets.forEach(target => target.classList.add('hidden'))
  }

  showElementForSelectedId () {
    const id = this.currentId
    if (!id) return

    const element = this.elementTargets.find(target => target.dataset.elementId === id)
    if (!element) return

    element.classList.remove('hidden')
  }

  get currentId () {
    for (const target of this.inputTargets) {
      // If the target is a select element or a checked radio button
      if (target.tagName === 'SELECT' || target.checked) {
        return target.value
      }
    }
  }
}
