import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['replaceable', 'newContent']

  replace (event) {
    if (event.target.value) {
      this.replaceableTarget.classList.add('hidden')
      this.newContentTarget.classList.remove('hidden')
    } else {
      this.replaceableTarget.classList.remove('hidden')
      this.newContentTarget.classList.add('hidden')
    }
  }
}
