import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const pinElement = $(this.element)
    pinElement.on('ajax:success', (event) => {
      const response = event.detail[2].response
      pinElement.replaceWith(response)
    })
  }
}
