import { transitionIn, transitionOut } from './bare_transitions'

document.addEventListener('turbo:before-stream-render', event => {
  if (animatedActions[event.target.action]) {
    event.preventDefault() // stop Turbo from automatically rendering the stream
    animatedActions[event.target.action](event)
  }
})

const animatedActions = {
  replace: (event) => {
    // unimplemented
    event.target.performAction()
  },
  remove: (event) => {
    event.target.targetElements.forEach(e => { transitionOut(e, () => e.remove()) })
  },
  prepend: (event) => {
    event.target.performAction()
    event.target.targetElements.forEach(e => { transitionIn(e.firstElementChild) })
  },
  append: (event) => {
    event.target.performAction()
    event.target.targetElements.forEach(e => { transitionIn(e.lastElementChild) })
  },
  update: (event) => {
    // unimplemented
    event.target.performAction()
  },
  before: (event) => {
    event.target.performAction()
    event.target.targetElements.forEach(e => { transitionIn(e.previousElementSibling) })
  },
  after: (event) => {
    event.target.performAction()
    event.target.targetElements.forEach(e => { transitionIn(e.nextElementSibling) })
  }
}
