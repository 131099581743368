import { Controller } from '@hotwired/stimulus'
import { transitionIn, transitionOut } from '../shared/bare_transitions'

export default class extends Controller {
  static targets = ['container', 'dialog', 'background']

  connect () {
    this.toggleClass = 'hidden'
  }

  disconnect () {
    this.close()
  }

  open (e) {
    this.containerTarget.innerHTML = e.detail.content

    // Lock the scroll and save current scroll position
    this.lockScroll()

    // Handle background
    transitionIn(this.backgroundTarget, () => this.backgroundTarget.classList.remove('hidden'))

    this.containerTarget.classList.remove(this.toggleClass)
    transitionIn(this.dialogTarget)
  }

  close (e) {
    if (e) e.preventDefault()

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll()

    // Hide the modal
    if (this.hasDialogTarget) {
      transitionOut(this.dialogTarget, () => {
        this.containerTarget.innerHTML = ''
        this.containerTarget.classList.add(this.toggleClass)
      })
    }

    // Remove the background
    transitionOut(this.backgroundTarget, () => this.backgroundTarget.classList.add('hidden'))

    // Dispatch close event
    this.element.dispatchEvent(new CustomEvent('modal-closed', {
      bubbles: true,
      cancelable: true
    }))
  }

  closeWithKeyboard (e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e)
    }
  }

  closeBackground (e) {
    if (e.target === this.containerTarget) {
      this.close(e)
    }
  }

  lockScroll () {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll () {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition () {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition () {
    document.documentElement.scrollTop = this.scrollPosition
  }
}
