import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'

export default class extends Controller {
  connect () {
    this.chartData = JSON.parse(this.element.dataset.chartData)
    this.chart = new Chart(this.element, this.chartData)
  }

  disconnect () {
    this.chart.destroy()
  }
}
