import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['select', 'recipient']
  connect () {
    this.tomSelect = new TomSelect(this.selectTarget, {
      onChange: this.onChange.bind(this),
      maxOptions: null
    })
  }

  onChange () {
    this.tomSelect.getValue().split(',').forEach((employeeDomId) => {
      this.addRecipient(employeeDomId)
    })
  }

  addRecipient (employeeDomId) {
    this.recipientTargets.forEach((recipient) => {
      if (recipient.id === employeeDomId) {
        $(recipient).show('fast')
        const employeeId = parseInt(employeeDomId.replace(/^\D+/g, ''))
        $('input[value="' + employeeId + '"]').prop('checked', true)
      }
    })
    this.tomSelect.clear()
  }

  removeRecipient (event) {
    event.preventDefault()

    const employeeDomId = $(event.currentTarget).data('employee-dom-id')
    $('.btn#' + employeeDomId).hide('fast')
    const employeeId = parseInt(employeeDomId.replace(/^\D+/g, ''))
    $('input[value="' + employeeId + '"]').prop('checked', false)
  }

  disconnect () {
    this.tomSelect.destroy()
  }
}
