import { Controller } from '@hotwired/stimulus'
import { computePosition, flip, offset, autoUpdate } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['button', 'content']

  connect () {
    this.contentTarget.addEventListener('toggle', this.toggle.bind(this))
  }

  toggle (event) {
    if (event.newState === 'closed') {
      this.unbindAutoUpdate()
    }
  }

  bindAutoUpdate () {
    this.cleanup = autoUpdate(this.buttonTarget, this.contentTarget, this.updatePosition.bind(this))
  }

  unbindAutoUpdate () {
    this.cleanup()
  }

  open () {
    this.updatePosition()
    this.bindAutoUpdate()
    this.contentTarget.showPopover()
  }

  close () {
    this.unbindAutoUpdate()
    this.contentTarget.hidePopover()
  }

  updatePosition () {
    computePosition(this.buttonTarget, this.contentTarget, {
      placement: 'bottom-end',
      middleware: [
        flip(),
        offset(10)
      ]
    }).then(({ x, y }) => {
      Object.assign(this.contentTarget.style, {
        left: `${x}px`,
        top: `${y}px`
      })
    })
  }
}
