import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'component'
  ]

  connect () {
    $.getJSON(this.data.get('controller-url'), (data) => {
      const unread = data.unread
      this._render(this.componentTarget, unread)
    })
  }

  _render (target, unread) {
    if (unread > 0) {
      this.element.classList.remove('hidden')
      target.innerText = unread
    } else {
      this.element.classList.add('hidden')
      target.innerText = ''
    }
  }
}
