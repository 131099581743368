import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'moreButton', 'lessButton']

  initialize () {
    this.initialHeight = this.contentClientHeight
  }

  connect () {
    if (this.contentScrollHeight > this.contentClientHeight) {
      this.moreButtonTarget.classList.remove('hidden')
    } else {
      this.contentTarget.classList.add('read-more-expanded')
    }
  }

  showMore () {
    if (this.contentTarget.classList.contains('read-more-expanded')) {
      return
    }

    this.contentTarget.style.maxHeight = this.contentScrollHeight + 'px'
    this.contentTarget.classList.add('read-more-expanded')
    this.moreButtonTarget.classList.add('hidden')
    this.lessButtonTarget.classList.remove('hidden')
  }

  showLess () {
    this.contentTarget.style.maxHeight = this.initialHeight + 'px'
    this.contentTarget.classList.remove('read-more-expanded')
    this.moreButtonTarget.classList.remove('hidden')
    this.lessButtonTarget.classList.add('hidden')
  }

  get contentScrollHeight () {
    return this.contentTarget.scrollHeight
  }

  get contentClientHeight () {
    return this.contentTarget.clientHeight
  }
}
