import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['priority', 'deliveryInfo', 'pharmacy']

  change () {
    const id = this.pharmacyTarget.value
    if (id) {
      this.priorityTarget.classList.remove('hidden')
      this.deliveryInfoTarget.classList.remove('hidden')
    } else {
      this.priorityTarget.classList.add('hidden')
      this.deliveryInfoTarget.classList.add('hidden')
    }
  }
}
