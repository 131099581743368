import { Controller } from '@hotwired/stimulus'
import Dropzone from 'dropzone'

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = [
    'categorySelect',
    'datepicker',
    'droparea',
    'notice',
    'innernotice',
    'okButton'
  ]

  connect () {
    this.dropzone = new Dropzone(this.dropareaTarget, {
      url: this.url,
      autoProcessQueue: true,
      paramName: 'document',
      maxFilesize: 100,
      headers: {
        'X-CSRF-Token': this.element.authenticity_token.value
      }
    })

    this.dropzone.on('sending', this._addParams.bind(this))
    this.dropzone.on('error', this._onError.bind(this))
    this.dropzone.on('success', this._onSuccess.bind(this))
  }

  disconnect () {
    this.dropzone.destroy()
  }

  categorySelected (event) {
    if (event.target.value !== '') {
      this.dropareaTarget.classList.remove('hidden')
    } else {
      this.dropareaTarget.classList.add('hidden')
    }
  }

  get url () {
    return this.element.action
  }

  _addParams (file, xhr, formData) {
    formData.append('category_id', this.categorySelectTarget.value)
    formData.append('date', this.datepickerTarget.value)
  }

  _onError (file, response) {
    this.noticeTarget.classList.remove('border-green-600', 'text-green-600', 'hidden')
    this.noticeTarget.classList.add('border-red-600', 'text-red-600')
    if (typeof response === 'string') {
      this.innernoticeTarget.innerHTML = response
    } else {
      this.innernoticeTarget.innerHTML = response.error
    }
  }

  _onSuccess (file, response) {
    this.noticeTarget.classList.remove('border-red-600', 'text-red-600', 'hidden')
    this.noticeTarget.classList.add('border-green-600', 'text-green-600')
    this.innernoticeTarget.innerHTML = response.message
    this.okButtonTarget.classList.remove('hidden')
  }
}
