// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import Trix from 'trix'
import '@rails/actiontext'
import translations from './shared/trix-translations'
import '@oddbird/popover-polyfill'

import './controllers'
import './shared/turbo_stream_animations'
import './shared/custom_turbo_streams'

import { Chart, LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, BarController, BarElement, CategoryScale } from 'chart.js'

Chart.register(LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, BarController, BarElement, CategoryScale)

window.$ = window.jQuery = require('jquery')

const translation = translations[document.documentElement.lang]
if (translation) {
  const toolbarButtonsNames = ['attachFiles', 'bold', 'italic', 'strike', 'link', 'unlink', 'urlPlaceholder', 'heading1', 'quote', 'code', 'bullets', 'numbers', 'undo', 'indent', 'outdent', 'redo']
  toolbarButtonsNames.forEach((buttonName) => {
    Trix.config.lang[`${buttonName}`] = translation[`${buttonName}`]
  })
}

Turbo.setConfirmMethod((message) => {
  const dialog = document.getElementById('turbo-confirm')
  dialog.querySelector('p').textContent = message
  dialog.showModal()

  return new Promise((resolve) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm')
    }, { once: true })
  })
})
