import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['required', 'submit']

  check () {
    if (this.readyForSubmit) {
      this.submitTarget.removeAttribute('disabled')
    } else {
      this.submitTarget.setAttribute('disabled', 'disabled')
    }
  }

  get readyForSubmit () {
    return this.requiredTargets.every(target => !!target.value)
  }
}
