import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { getFlatpickrLocale } from '../shared/flatpickr_locale'
import { computePosition, flip, autoUpdate } from '@floating-ui/dom'

export default class extends Controller {
  connect () {
    const locale = getFlatpickrLocale()
    const pickerType = this.data.get('picker-type') || 'date'
    const options = this._picker_options(pickerType, locale)
    const dialog = this.element.closest('dialog')

    // if inside dialog we need to create a new dialog element inside the dialog
    // and put the datepicker in there and position it, so it shows on top of the dialog
    // the datepicker is displayed inline, this way we can be the ones to position it
    if (dialog) {
      this.newDialog = document.createElement('dialog')
      this.newDialog.classList.add('m-0', 'p-0', 'overflow-hidden')
      this.newDialog.popover = 'auto'
      dialog.appendChild(this.newDialog)
      options.appendTo = this.newDialog
      options.inline = true
      this.element.addEventListener('click', () => {
        this.open()
      })
      options.onChange = (_date) => {
        // Only close the datepicker if it's a date picker, not a datetime picker
        // This is to prevent the picker to be closed when selecting the time of a datetime picker
        // This is also the default behavior of flatpickr
        if (pickerType === 'date') {
          this.close()
        }
      }
    }

    this.datepicker = flatpickr(this.element, options)
  }

  open () {
    this.startPositioning()
    this.newDialog.showPopover()
    this.updatePosition()
  }

  startPositioning () {
    this.positioningCleanup = autoUpdate(this.element, this.newDialog, this.updatePosition.bind(this))
  }

  disconnect () {
    const dateValue = this.element.value
    this.datepicker.destroy()
    this.element.value = dateValue
  }

  close () {
    this.positioningCleanup()
    this.newDialog.hidePopover()
  }

  updatePosition () {
    computePosition(this.element, this.newDialog, {
      placement: 'bottom-start',
      middleware: [
        flip()
      ]
    }).then(({ x, y }) => {
      Object.assign(this.newDialog.style, {
        left: `${x}px`,
        top: `${y}px`
      })
    })
  }

  _picker_options (pickerType, locale) {
    switch (pickerType) {
      case 'date':
        return { dateFormat: 'd-m-Y', locale, disableMobile: true }
      case 'datetime':
        return { dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale, disableMobile: true }
    }
  }
}
