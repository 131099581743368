import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['anchor']

  encode ({ target: { name, value, type, checked } }) {
    for (const anchor of this.anchorTargets) {
      const searchParams = new URLSearchParams(anchor.search)
      if (type === 'checkbox' && !checked) {
        searchParams.delete(name)
      } else {
        searchParams.set(name, value)
      }
      anchor.search = searchParams
    }
  }
}
