import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  expandAll (event) {
    event.preventDefault()
    document.dispatchEvent(new CustomEvent('expand-all', {
      bubbles: true,
      cancelable: true
    }))
  }
}
