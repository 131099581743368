import { Controller } from '@hotwired/stimulus'
import once from 'lodash/once'
import { get } from '@rails/request.js'
import { openModal } from '../shared/modal_events'

export default class extends Controller {
  // Areas where the modal cannot be opened
  static targets = ['disabled']

  initialize () {
    this.loadSrcDialog = once(this.loadSrcDialog)
  }

  async open (e) {
    // Prevent IE11 from opening the modal if the clicked on element is disabled
    if (e.target.disabled) {
      return
    }

    if (this.disabledTargets.some(target => target.contains(e.target))) {
      return
    }

    e.preventDefault()
    e.target.blur()

    if (this.src) {
      await this.loadSrcDialog()
      openModal(this.dialogHtml)
    } else {
      openModal(this.modalDialog.innerHTML)
    }
  }

  async loadSrcDialog () {
    if (!this.modalDialog) return

    const response = await get(this.src, { headers: { Accept: 'text/javascript' } })
    this.dialogHtml = await response.html
  }

  get src () {
    return this.data.get('src')
  }

  get modalDialog () {
    return document.getElementById(this.data.get('modalId'))
  }
}
