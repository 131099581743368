import { Controller } from '@hotwired/stimulus'
import { Chart, registerables } from 'chart.js'
import { format } from 'date-fns'
import 'chartjs-adapter-date-fns'

export default class extends Controller {
  static values = {
    type: String,
    data: Array,
    yLabels: Object,
    timeScaling: Boolean,
    startAt: Number,
    endAt: Number,
    stepped: Boolean,
    hideBackground: Boolean,
    hideLine: Boolean,
    backgroundSolid: Boolean,
    backgroundOpacity: Number,
    sharp: Boolean,
    ticksLimit: Number,
    yTicksLimit: Number,
    tooltip: String,
    unit: String,
    labels: Array,
    yLabelsRight: Object,
    tickInterval: Number,
    plotline: Number
  }

  colors = [
    { border: 'rgb(30, 144, 255)', background: 'rgb(30, 144, 255, 0.2)' },
    { border: 'rgb(144, 255, 30)', background: 'rgb(144, 255, 30, 0.2)' },
    { border: 'rgb(255, 30, 144)', background: 'rgb(255, 30, 144, 0.2)' },
    { border: 'rgb(255, 144, 30)', background: 'rgb(255, 144, 30, 0.2)' },
    { border: 'rgb(144, 30, 255)', background: 'rgb(144, 30, 255, 0.2)' }
  ]

  currentColorItteration = 0

  connect () {
    Chart.register(...registerables)
    this.chart = this.renderChart()
  }

  disconnect () {
    this.chart.destroy()
  }

  renderChart () {
    const chartData = {
      type: this.typeValue,
      data: this.getChartData(),
      options: this.getChartOptions(),
      plugins: []
    }
    if (this.hasPlotlineValue) {
      chartData.plugins.push(this.drawLinePlugin())
    }
    return new Chart(this.element, chartData)
  }

  getChartData () {
    return this.createChart()
  }

  drawLinePlugin () {
    const beforeDatasetsDraw = (chart, arg, options) => {
      if (Number.isNaN(this.plotlineValue)) return

      const { ctx, chartArea: { left, width }, scales: { y } } = chart
      ctx.save()

      ctx.strokeStyle = 'rgb(72, 187, 120)'
      ctx.strokeRect(left, y.getPixelForValue(this.plotlineValue), width, 1)
      ctx.restore()
    }

    return {
      id: 'lines',
      beforeDatasetsDraw
    }
  }

  createChart () {
    const chart = {
      datasets: this.dataValue.map(dataset => this.createDataset(dataset.label, dataset.data))
    }
    if (this.typeValue === 'bar') chart.labels = this.labelsValue
    return chart
  }

  createDataset (label, data) {
    const color = this.getNextColor()
    if (this.backgroundSolidValue) color.background = color.border
    color.background = color.background.replace('0.2', this.backgroundOpacityValue)
    const extraAttributes = this.getDataAttributes()
    return {
      ...extraAttributes,
      label,
      data,
      backgroundColor: color.background,
      borderColor: color.border,
      pointBackgroundColor: color.border,
      pointRadius: 2
    }
  }

  getNextColor () {
    if (this.currentColorItteration >= this.colors.length) this.currentColorItteration = 0
    const color = this.colors[this.currentColorItteration]
    this.currentColorItteration++
    return color
  }

  getDataAttributes () {
    const extraData = {
      fill: !this.hideBackgroundValue,
      showLine: !this.hideLineValue,
      stepped: this.steppedValue
    }
    if (this.sharpValue) extraData.lineTension = 0

    return extraData
  }

  getChartOptions () {
    const options = {
      scales: {
        x: this.getXAxesOptions(),
        y: this.getYAxesOptions()
      },
      annotation: {
        annotations: [{
          type: 'line',
          mode: 'horizontal',
          value: 5,
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 4,
          label: {
            enabled: false
          }
        }]
      },
      plugins: {
        ...this.getTooltipOptions(),
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 6

          },
          onClick: null
        }
      }
    }

    if (this.hasYLabelsRightValue) options.scales.y1 = this.secondYAxis()
    return options
  }

  getTooltipOptions () {
    const options = {
      tooltip: {
        usePointStyle: true,
        callbacks: {
          label: (context) => {
            if (this.hasYLabelsValue) return `${this.tooltipValue}: ${this.yLabelsValue[context.raw]}`
            return `${this.tooltipValue}: ${context.raw.toFixed(2)}`
          }
        }
      }
    }

    if (this.timeScalingValue) {
      options.tooltip.callbacks.label = (context) => {
        return `${context.raw.y} ${this.unitValue}`
      }

      options.tooltip.callbacks.title = (items) => {
        return format(items[0].parsed.x, 'dd-MM-yyyy')
      }
    }

    return options
  }

  getYAxesOptions () {
    const axes = {
      display: true,
      suggestedMin: this.startAtValue,
      plugins: {
        title: {
          display: true,
          text: this.unitValue
        }
      },
      grid: {
        display: true
      },
      ticks: {}
    }
    if (this.endAtValue) axes.max = this.endAtValue
    if (this.typeValue !== 'bar') axes.ticks.maxTicksLimit = this.yTicksLimitValue

    if (this.tickIntervalValue) axes.ticks.stepSize = this.tickIntervalValue
    if (this.hasYLabelsValue) {
      axes.ticks.callback = (value, index, values) => {
        return this.yLabelsValue[value.toString()]
      }
    }

    return axes
  }

  secondYAxis () {
    const axes = this.getYAxesOptions()
    axes.position = 'right'

    axes.ticks.callback = (value, index, values) => {
      return this.yLabelsRightValue[value.toString()]
    }

    return axes
  }

  getXAxesOptions () {
    const axes = {
      ticks: {
        maxTicksLimit: this.ticksLimitValue
      },
      scaleLabel: {
        display: true,
        labelString: (this.timeScalingValue ? 'Date' : '')
      }
    }
    if (this.timeScalingValue) {
      axes.type = 'time'
      axes.time = { unit: 'day' }
    }

    return axes
  }
}
