import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hidden', 'selector']
  static values = {
    show: String,
    hide: String
  }

  connect () {
    this.toggleElements()
  }

  toggleElements () {
    if (this.selectorTarget.value === this.element.getAttribute('show')) {
      this.hiddenTargets.forEach(el => {
        el.classList.remove('hidden')
        document.querySelector('label[for=' + el.getAttribute('id') + ']').classList.remove('hidden')
      })
    } else if (this.selectorTarget.value === this.element.getAttribute('hide')) {
      this.hiddenTargets.forEach(el => {
        el.classList.add('hidden')
        document.querySelector('label[for=' + el.getAttribute('id') + ']').classList.add('hidden')
      })
    }
  }
}
