import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  async ensure () {
    if (document.hidden) return

    const response = await get('/current_session', { responseKind: 'json' })
    const json = await response.json

    if (!json.signed_in) {
      window.Turbo.visit('/users/login')
    }
  }
}
