import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const backLink = this.element.innerHTML
    $('.header').prepend(backLink)
    if ($('.header').children().length === 2) {
      $('.header').append('<div class="w-10"></div>')
    }
  }

  removeLink () {
    $('.header a#back-link-mobile').remove()
  }
}
