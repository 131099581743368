import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['address', 'priority']

  connect () {
    this.addressTarget.classList.add('hidden')
    this.change()
  }

  change () {
    const index = this.priorityTarget.selectedIndex
    if (index === 1 || index === 2) {
      this.addressTarget.classList.remove('hidden')
    } else {
      this.addressTarget.classList.add('hidden')
    }
  }
}
