import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['packages', 'drugs']

  connect () {
    this.packagesTarget.disabled = true
  }

  async updatePackages (event) {
    if (event.target.value === '') {
      this.packagesTarget.disabled = true
    } else {
      this.packagesTarget.disabled = false
      const drugId = this.getSelectedDrugId()
      const path = this.element.dataset.packagesUrl
      const packagesHtml = await $.get(path, { drug_id: drugId })

      if (packagesHtml) {
        this.packagesTarget.innerHTML = packagesHtml
      } else {
        this.packagesTarget.disabled = true
        this.packagesTarget.innerHTML = '<option value="">Ingen pakningsinformation</option>'
      }
    }
  }

  getSelectedDrugId () {
    const tomselect = this.drugsTarget.tomselect
    const id = tomselect.getValue()
    const selectedOption = tomselect.options[id]
    return selectedOption.drug_id
  }
}
