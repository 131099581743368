import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'info', 'form', 'giveBtn', 'provisionBtn', 'deviateBtn', 'packageBtn']

  connect () {
    this.disableButtons()
    this.setDefaultMessage()
  }

  checkPrePackageable (event) {
    this.checkTypeAtTimestamp('prePackageable', event.target.dataset.timestamp)
  }

  checkDoseDispensed (event) {
    this.checkTypeAtTimestamp('doseDispensed', event.target.dataset.timestamp)
  }

  checkTypeAtTimestamp (type, timestamp) {
    this.checkboxTargets.forEach(c => {
      if (c.checked) return

      const sameType = c.dataset[type] === 'true'
      const sameTimestamp = c.dataset.timestamp === timestamp
      c.checked = sameTimestamp && sameType
    })

    this.handleChecked()
  }

  handleChecked () {
    const checkedTargets = this.getCheckedTargets()

    if (checkedTargets.length === 0) {
      this.setDefaultMessage()
      this.disableButtons()
      return
    }

    this.infoTarget.innerHTML = this.formatTargets(checkedTargets)

    const givenSelected = checkedTargets.some(el => el.dataset.status === 'given')
    const deviatedSelected = checkedTargets.some(el => el.dataset.status === 'deviated')
    const provisionedSelected = checkedTargets.some(el => el.dataset.status === 'provisioned')
    const unassignedSelected = checkedTargets.some(el => el.dataset.status === 'unassigned')
    const packagedSelected = checkedTargets.some(el => el.dataset.status === 'packaged')

    const mixedSelected = deviatedSelected + provisionedSelected + unassignedSelected + givenSelected + packagedSelected > 1

    this.enableButtons()

    if (mixedSelected) {
      this.disableButtons()
      this.setMixedSelectedMessage()
    } else if (provisionedSelected || givenSelected) {
      this.provisionBtnTarget.disabled = true
      this.giveBtnTarget.disabled = true
      this.packageBtnTarget.disabled = true
    } else if (packagedSelected) {
      this.packageBtnTarget.disabled = true
    }

    // Disable package button if not all selected are pre-packageable
    const prePackageableSelected = checkedTargets.every(el => el.dataset.prePackageable === 'true' && el.dataset.doseDispensed === 'false')
    if (!prePackageableSelected) {
      this.packageBtnTarget.disabled = true
    }
  }

  setDefaultMessage () {
    this.infoTarget.innerHTML = this.infoTarget.dataset.defaultMessage
  }

  setMixedSelectedMessage () {
    this.infoTarget.innerHTML = this.infoTarget.dataset.mixedSelectedMessage
  }

  getCheckedTargets () {
    return this.checkboxTargets.filter(c => c.checked)
  }

  formatTargets (targets) {
    return targets.map(({ dataset }) => {
      return `${dataset.timestamp}: ${dataset.name}`
    }).join(', ')
  }

  change () {
    this.handleChecked()
  }

  give () {
    this.submitForm({
      kind: 'give'
    })
  }

  provision () {
    this.submitForm({
      kind: 'provision'
    })
  }

  package () {
    this.submitForm({
      kind: 'package'
    })
  }

  deviation ({ detail: { message } }) {
    this.submitForm({
      kind: 'deviation',
      message
    })
  }

  enableButtons () {
    this.giveBtnTarget.disabled = false
    this.provisionBtnTarget.disabled = false
    this.deviateBtnTarget.disabled = false
    this.packageBtnTarget.disabled = false
  }

  disableButtons () {
    this.giveBtnTarget.disabled = true
    this.provisionBtnTarget.disabled = true
    this.deviateBtnTarget.disabled = true
    this.packageBtnTarget.disabled = true
  }

  setRegistering () {
    this.giveBtnTarget.value = this.giveBtnTarget.dataset.disableWith
    this.provisionBtnTarget.value = this.provisionBtnTarget.dataset.disableWith
    this.deviateBtnTarget.value = this.deviateBtnTarget.dataset.disableWith
    this.packageBtnTarget.value = this.packageBtnTarget.dataset.disableWith
  }

  async submitForm ({ kind, message }) {
    this.disableButtons()
    this.setRegistering()

    const formData = new FormData()

    this.getCheckedTargets().forEach(({ dataset }) => {
      formData.append('medicine_log_prescription_id[]', dataset.prescriptionId)
      formData.append('medicine_log_dosage[]', dataset.dosage)
      formData.append('medicine_log_timestamp[]', dataset.timestamp)
    })

    formData.append('kind', kind)
    formData.append('child_id', this.formTarget.dataset.childId)
    formData.append('date', this.formTarget.dataset.date)
    formData.append('message', message || '')

    await $.ajax({
      url: '/medicine/dosage_logs',
      data: formData,
      processData: false,
      contentType: false,
      type: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
  }
}
