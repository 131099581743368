import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'checkbox', 'drugMedicationIds']

  connect () {

  }

  change (e) {
    const drugMedicationIds = this.checkboxTargets
      .filter(checkbox => checkbox.checked === true)
      .map(checkbox => {
        return checkbox.dataset.drugMedicationId
      })

    this.drugMedicationIdsTarget.value = drugMedicationIds
  }
}
