import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'content',
    'icon'
  ]

  static values = {
    hideIcon: String,
    showIcon: String
  }

  toggleSpoiler () {
    this.contentTarget.classList.toggle('max-h-0')
    this.contentTarget.classList.toggle('max-h-96')

    if (this.hideIconValue) {
      this.iconTarget.classList.toggle(this.hideIconValue)
      this.iconTarget.classList.toggle(this.showIconValue)
    }
  }
}
