import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [
    'displayArea'
  ]

  connect () {
    this.search = debounce(this.search, 250)
  }

  search (e) {
    const query = e.target.value
    if (e.target.value === '') {
      this.displayAreaTarget.innerHTML = ''
    }

    $.get('/super_admin/employee_searches', { query }, (data) => {
      this.displayAreaTarget.innerHTML = data
    })
  }
}
