import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash/debounce'

export default class extends Controller {
  static targets = ['submitButton', 'mailWarning', 'mailInput']

  initialize () {
    this.validateCertificate = debounce(this.validateCertificate, 200)
  }

  validateCertificate () {
    this.mailInputTargets.forEach((input, index) => {
      const inputEmail = input.value

      if (inputEmail.length === 0) {
        this.clearEmailStatus(index)
        this.clearWarning(index)
        return
      }

      $.get('/secure_mails/validate_secure_mail', { mail: inputEmail })
        .done(() => this.validCertificateFound(index))
        .fail(() => this.validCertificateNotFound(index))
    })
  }

  clearEmailStatus (index) {
    this.removeClasses(this.mailInputTargets[index], ['border-red-500', 'border-green-500'])
  }

  validCertificateFound (index) {
    this.removeClasses(this.mailInputTargets[index], ['border-red-500'])
    this.mailInputTargets[index].classList.add('border-green-500')
    this.clearWarning(index)
    this.submitButtonTarget.disabled = this.mailInputTargets.some(input => input.classList.contains('border-red-500'))
  }

  validCertificateNotFound (index) {
    this.removeClasses(this.mailInputTargets[index], ['border-green-500'])
    this.mailInputTargets[index].classList.add('border-red-500')
    this.addWarning(index)
    this.submitButtonTarget.disabled = true
  }

  clearWarning (index) {
    this.mailWarningTargets[index].classList.add('hidden')
  }

  addWarning (index) {
    this.mailWarningTargets[index].classList.remove('hidden')
  }

  removeClasses (element, classes) {
    if (element) {
      classes.forEach(cls => element.classList.remove(cls))
    }
  }
}
