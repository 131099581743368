import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = ['city']

  connect () {
    this.updateCityName = debounce(this.updateCityName, 250)
  }

  updateCityName (e) {
    const postalCode = e.target.value
    if (e.target.value === '') {
      return
    }

    $.get(`/postal_codes/${postalCode}`, (data) => {
      if (data !== '') {
        this.cityTarget.value = data
      }
    })
  }
}
