import { Danish } from 'flatpickr/dist/l10n/da.js'
import { Norwegian } from 'flatpickr/dist/l10n/no.js'

export function getFlatpickrLocale () {
  if (document.documentElement.lang === 'da') {
    return Danish
  } else {
    return Norwegian
  }
}
