export function openModal (content) {
  document.dispatchEvent(new CustomEvent('modal:open', {
    bubbles: true,
    cancelable: true,
    detail: { content }
  }))
}

export function closeModal () {
  document.dispatchEvent(new CustomEvent('modal:close', {
    bubbles: true,
    cancelable: true
  }))
}
