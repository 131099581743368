import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accessCheckbox', 'email', 'giveWallAccess', 'nameField', 'passwordFields', 'personSelect']

  connect () {
    if (!this.hasAccessCheckboxTarget) return

    const personSelected = (this.hasPersonSelectTarget && this.personSelectTarget.value !== '')
    const personId = this.personSelectTarget.value
    const person = document.querySelectorAll("[data-id='" + personId + "']")[0]
    const showFields = (person.dataset.email === '' || person.dataset.requiresPassword === 'true')
    if (personSelected) {
      this.nameFieldTarget.dataset.showFields = showFields
    }
    if (!this.accessCheckboxTarget.checked || !showFields) {
      this.passwordFieldsTarget.classList.add('hidden')
    }
  }

  setPasswordFields (event) {
    if (!this.hasAccessCheckboxTarget) return

    const showFields = (event.target.dataset.requiresPassword === 'true' || event.target.dataset.email === '')
    this.nameFieldTarget.dataset.showFields = showFields
    this.emailTarget.value = event.target.dataset.email
    if (this.accessCheckboxTarget.checked) {
      if (showFields) {
        this.passwordFieldsTarget.classList.remove('hidden')
      } else {
        this.passwordFieldsTarget.classList.add('hidden')
        // reset email and password fields
        this.emailTarget.value = ''
        const inputs = this.passwordFieldsTarget.getElementsByTagName('input')
        const inputArray = Array.prototype.slice.call(inputs)
        inputArray.forEach(inputElement => {
          inputElement.value = ''
        })
      }
    }
  }

  handleAccessCheckbox () {
    if (!this.hasAccessCheckboxTarget) return

    if (this.accessCheckboxTarget.checked) {
      this.giveWallAccessTarget.value = '1'
      if (!this.hasNameFieldTarget || (this.nameFieldTarget.value !== '' && this.nameFieldTarget.dataset.showFields === 'true')) {
        this.passwordFieldsTarget.classList.remove('hidden')
      }
    } else {
      this.giveWallAccessTarget.value = '0'
      this.passwordFieldsTarget.classList.add('hidden')
    }
  }
}
