import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['packages', 'drugs', 'quantity', 'unit', 'method', 'methodInput', 'unitInput', 'numPackages']

  connect () {
    this.hideOptional()
  }

  hideOptional () {
    this.packagesTarget.classList.add('hidden')
    this.quantityTarget.classList.add('hidden')
    this.unitTarget.classList.add('hidden')
    this.methodTarget.classList.add('hidden')
    this.numPackagesTarget.classList.add('hidden')
    this.methodInputTarget.value = ''
  }

  async drugUpdated (event) {
    const drugId = event.target.value
    if (drugId === '') {
      this.hideOptional()
    } else {
      this.methodTarget.classList.remove('hidden')
      this.quantityTarget.disabled = false
      this.getDrugUnit(drugId)
    }
  }

  async getDrugUnit (drugId) {
    // const path = this.element.dataset.drugMedicationsUrl
    // const unit = await $.get(path + '/' + drugId)
    // if (unit !== '') {
    //   this.unitInputTarget.value = unit
    // } else {
    this.unitInputTarget.value = ''
    // }
  }

  methodChanged (event) {
    const method = event.target.value
    if (method === '') {
      this.packagesTarget.classList.add('hidden')
      this.quantityTarget.classList.add('hidden')
      this.numPackagesTarget.classList.add('hidden')
      this.unitTarget.classList.add('hidden')
    } else if (method === 'Indgivet, hel pakning' || method === 'Udleveret, hel pakning') {
      this.packagesTarget.classList.remove('hidden')
      this.numPackagesTarget.classList.remove('hidden')
      this.quantityTarget.classList.add('hidden')
      this.unitTarget.classList.add('hidden')
    } else {
      this.packagesTarget.classList.add('hidden')
      this.numPackagesTarget.classList.add('hidden')
      this.quantityTarget.classList.remove('hidden')
      this.unitTarget.classList.remove('hidden')
    }
  }

  getSelectedDrugId () {
    return this.drugsTarget.children[this.drugsTarget.selectedIndex].dataset.drugIdentifier
  }
}
