import Danish from '@fullcalendar/core/locales/da'
import Norwegian from '@fullcalendar/core/locales/nb'

export function getFullCalendarLocale () {
  if (document.documentElement.lang === 'da') {
    return Danish
  } else {
    return Norwegian
  }
}
