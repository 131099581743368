import { Controller } from '@hotwired/stimulus'
import { Sortable } from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = { url: String }

  connect () {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: '.handle',
      onEnd: this.updatePositions.bind(this)
    })
  }

  disconnect () {
    this.sortable.destroy()
  }

  updatePositions (event) {
    if (!this.urlValue) {
      console.warn('No URL provided for sorting')
      return
    }

    const positions = this.itemTargets.map((item) => item.dataset.sortableId)
    patch(this.urlValue, {
      body: {
        positions
      }
    })
  }
}
