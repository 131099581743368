import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    window.addEventListener('resize', this.handler.bind(this))
    window.addEventListener('orientationchange', this.handler.bind(this))

    this.handler()
  }

  handler () {
    const newHeight = `${window.innerHeight}px`
    this.element.style.height = newHeight
  }
}
