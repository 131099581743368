import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggleAreaShow', 'toggleArea']

  connect () {
    this.toggle()
  }

  toggle () {
    if (this.toggleAreaShowTarget.checked) {
      this.toggleAreaTarget.classList.remove('hidden')
    } else {
      this.toggleAreaTarget.classList.add('hidden')
    }
  }
}
