import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputField', 'inputSelect']

  connect () {
    this.toggle()
  }

  toggle () {
    this.inputFieldTargets.filter(element => element.dataset.value === this.inputSelectTarget.value).forEach(inputField => inputField.classList.add('hidden'))
    this.inputFieldTargets.filter(element => element.dataset.value !== this.inputSelectTarget.value).forEach(inputField => inputField.classList.remove('hidden'))
  }
}
