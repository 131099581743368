import { Controller } from '@hotwired/stimulus'

const scrollPositions = new Map()

export default class extends Controller {
  static targets = [
    'currentTab'
  ]

  initialize () {
    this.saveScrollPosition = this.saveScrollPosition.bind(this)
  }

  connect () {
    document.addEventListener('turbo:before-cache', this.saveScrollPosition)
    this.element.scrollTop = this.scrollPosition.top
    this.element.scrollLeft = this.scrollPosition.left

    this.handleCurrentTabNotVisible()
  }

  disconnect () {
    document.removeEventListener('turbo:before-cache', this.saveScrollPosition)
  }

  saveScrollPosition () {
    scrollPositions.set(this.scrollKey, { top: this.element.scrollTop, left: this.element.scrollLeft })
  }

  handleCurrentTabNotVisible () {
    if (!this.hasCurrentTabTarget) return

    if (!inViewportHorizontal(this.element, this.currentTabTarget)) {
      if (this.currentTabTarget.offsetLeft <= this.element.scrollLeft) {
        this.element.scrollLeft = this.currentTabTarget.offsetLeft
      } else {
        this.element.scrollLeft = this.currentTabTarget.offsetLeft + this.currentTabTarget.clientWidth - this.element.clientWidth
      }
    }
    if (!inViewportVertical(this.element, this.currentTabTarget)) {
      if (this.currentTabTarget.offsetTop <= this.element.scrollTop) {
        this.element.scrollTop = this.currentTabTarget.offsetTop
      } else {
        this.element.scrollTop = this.currentTabTarget.offsetTop + this.currentTabTarget.clientHeight - this.element.clientHeight
      }
    }
  }

  get scrollKey () {
    return this.data.get('key')
  }

  get scrollPosition () {
    return scrollPositions.get(this.scrollKey) || { top: 0, left: 0 }
  }
}

function inViewportHorizontal (container, element) {
  const scrollLeft = container.scrollLeft
  const containerRight = scrollLeft + container.clientWidth
  const left = element.offsetLeft
  const right = left + element.clientWidth
  return left >= scrollLeft && right <= containerRight
}

function inViewportVertical (container, element) {
  const scrollTop = container.scrollTop
  const containerBottom = scrollTop + container.clientHeight
  const top = element.offsetTop
  const bottom = top + element.clientHeight
  return top >= scrollTop && bottom <= containerBottom
}
