import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'customTemplate',
    'dosageSchedules',
    'notIteratedSchemaTemplate',
    'pauseTemplate',
    'pnTemplate',
    'schemaTemplate',
    'standardTemplate'
  ]

  addStandardSchedule (e) {
    e.preventDefault()
    $(this.dosageSchedulesTarget).append(this.standardTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()))
  }

  addSchemaSchedule (e) {
    e.preventDefault()
    $(this.dosageSchedulesTarget).append(this.schemaTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()))
  }

  addCustomSchedule (e) {
    e.preventDefault()
    $(this.dosageSchedulesTarget).append(this.customTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()))
  }

  addPnSchedule (e) {
    e.preventDefault()
    $(this.dosageSchedulesTarget).append(this.pnTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()))
  }

  addNotIteratedSchemaSchedule (e) {
    e.preventDefault()
    $(this.dosageSchedulesTarget).append(this.notIteratedSchemaTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()))
  }

  addPauseSchedule (e) {
    e.preventDefault()
    $(this.dosageSchedulesTarget).append(this.pauseTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()))
  }

  removeSchedule (event) {
    event.preventDefault()

    const wrapper = event.target.closest('.nested-fields')
    wrapper.remove()
  }
}
