// ==========
// = Colors =
// ==========

// Guess constrast color (black/white)
// used in Calendar events
//
// Test:
//   contrastColor('#BBB') # => '#000'
//   contrastColor('#123') # => '#FFF'
export function contrastColor (color) {
  if (colorBrightness(color) > 125) {
    return '#000'
  } else {
    return '#FFF'
  }
}

// W3C's formula to calculate a color's brightness; difference between
// background and foreground brightness should be greater than 125.
//
// Test:
//   colorBrightness('#000') # => 0
//   colorBrightness('#FFF') # => 255
//   colorBrightness('#123') # => 30.855
//   colorBrightness('#AAA') # => 170
function colorBrightness (color) {
  const colors = splitColorParts(color)
  const red = parseInt(colors[1], 16)
  const green = parseInt(colors[2], 16)
  const blue = parseInt(colors[3], 16)

  return ((red * 299) + (green * 587) + (blue * 114)) / 1000
}

// Split CSS color string into parts
//
// Test:
//   splitColorParts('#FFAAE2') # => ["#FFAAE2", "FF", "AA", "E2"]
//   splitColorParts('#123') # => ["#123", "11", "22", "33"]
//   splitColorParts('abcd') # => undefined
function splitColorParts (color) {
  const pattern = /^#(\w{2})(\w{2})(\w{2})$/
  const pattern1 = /^#(\w)(\w)(\w)$/

  let colors = pattern.exec(color)
  if (colors != null) {
    return colors
  } else {
    colors = pattern1.exec(color)
    if (colors != null) {
      return [colors[0], '' + colors[1] + colors[1], '' + colors[2] + colors[2], '' + colors[3] + colors[3]]
    }
  }
}
