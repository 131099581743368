import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'hidden',
    'commitButton'
  ]

  message (event) {
    const number = event.target.dataset.number
    const value = event.target.value
    window.dispatchEvent(new CustomEvent('fieldChanged', { detail: { number, value } }))
  }

  reflect (event) {
    const number = event.detail.number
    const value = event.detail.value
    this.hiddenTargets.forEach((target) => {
      if (target.dataset.number === number) {
        target.value = value
      }
    })
  }

  commitMessage () {
    window.dispatchEvent(new CustomEvent('commitMessage'))
  }

  commit (event) {
    this.commitButtonTarget.click()
  }
}
