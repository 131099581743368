import { Controller } from '@hotwired/stimulus'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timegridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { getFullCalendarLocale } from '../shared/calendar_locale'
import { contrastColor } from '../shared/color.js'
import { openModal } from '../shared/modal_events'

export default class extends Controller {
  static targets = [
    'colorForm',
    'container',
    'calendar',
    'detailsDialog',
    'loading',
    'birthdayTemplate'
  ]

  connect () {
    this.locale = getFullCalendarLocale()
    this._initSelectedCalendars()
    this._initCalendar()
  }

  disconnect () {
    this.calendar.destroy()
  }

  get sidebar () {
    return document.querySelector('#right-sidebar')
  }

  toggleCalendar (event) {
    const target = event.currentTarget
    const checked = target.checked
    const calendarId = target.dataset.id

    if (checked) {
      this.calendar.addEventSource(this._eventSourceFromTarget(target))
    } else {
      this.calendar.getEventSourceById(calendarId).remove()
    }

    this._saveCalendarList()
  }

  toggleCalendarList (event) {
    event.currentTarget.classList.toggle('expanded')
    this.sidebar.classList.toggle('w-0')
    this.sidebar.classList.toggle('m-0')
    this.sidebar.classList.toggle('p-0')
    this.sidebar.classList.toggle('overflow-hidden')
  }

  showAll () {
    this.calendarTargets.forEach(target => {
      if (!target.checked) {
        target.click()
      }
    })
  }

  showNone () {
    this.calendarTargets.forEach(target => {
      if (target.checked) {
        target.click()
      }
    })
  }

  toggleColorEditing () {
    this.colorFormTargets.forEach(target => target.classList.toggle('hidden'))
  }

  colorChanged (event) {
    const newEventSource = this.eventSources.find(eventSource => eventSource.id === event.detail.calendarId)
    // There is no way to update the color on an event source,
    // so instead it is removed and then readded to the calendar with the new color
    this.calendar.getEventSourceById(event.detail.calendarId).remove()
    this.calendar.addEventSource(newEventSource)
  }

  get eventSources () {
    return this.calendarTargets
      .filter(target => target.checked)
      .map(target => this._eventSourceFromTarget(target))
  }

  _initCalendar () {
    const hideButton = window.matchMedia('(max-width: 1024px)').matches ? '' : 'hideCalendarListButton'

    const self = this

    this.calendar = new Calendar(this.containerTarget, {
      height: 'auto',
      customButtons: {
        hideCalendarListButton: {
          icon: 'expand',
          click: this.toggleCalendarList.bind(this)
        }
      },
      locale: this.locale,
      plugins: [
        dayGridPlugin,
        timegridPlugin,
        interactionPlugin
      ],
      header: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: `prev next today ${hideButton}`
      },
      eventSources: this.eventSources,
      defaultDate: defaultDate(),
      firstHour: 9,
      timeFormat: 'HH:mm',
      displayEventEnd: true,
      firstDay: 1,
      nextDayThreshold: '00:00:00',
      weekNumbers: true,
      selectable: true,
      selectMirror: true,
      selectMinDistance: 5, // This is needed so the select isn't triggered when a day number is clicked
      editable: false,
      fixedWeekCount: false,
      select: selectCallback,
      eventClick: function (info) {
        info.jsEvent.preventDefault()

        const url = info.event.url
        if (url !== '#') {
          $.get(url, (data) => {
            openModal(data)
          })
        } else {
          let birthdayDialogTemplate = self.birthdayTemplateTarget.innerHTML
          const date = new Date(info.event.start)
          const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
          const eventDate = date.toLocaleDateString($('html').attr('lang'), options)

          birthdayDialogTemplate = birthdayDialogTemplate.replace('TITLE', eventDate)
          birthdayDialogTemplate = birthdayDialogTemplate.replace('BIRTHDAY_TEXT', info.event.title)
          openModal(birthdayDialogTemplate)
        }
      },
      dateClick: info => {
        if ($(info.jsEvent.target).is('span.fc-day-number')) {
          this.calendar.changeView('timeGridDay', info.date)
        } else {
          Turbo.visit('/events/new?start=' + info.date.toISOString() + '&end=' + info.date.toISOString())
        }
      },
      loading: doneLoading => {
        if (doneLoading) this.loadingTarget.classList.remove('hidden')
        else this.loadingTarget.classList.add('hidden')
      }
    })

    this.calendar.render()
  }

  _eventSourceFromTarget (target) {
    const color = target.dataset.color
    const calendarId = target.dataset.id

    return {
      id: calendarId,
      url: '/calendars/' + calendarId + '/events.json',
      color,
      textColor: contrastColor(color)
    }
  }

  _initSelectedCalendars () {
    const savedCalendars = this._loadCalendarList()

    if (savedCalendars) {
      for (const target of this.calendarTargets) {
        const calendarIsChecked = savedCalendars[target.dataset.id]
        const calendarIsNew = calendarIsChecked === undefined

        target.checked = calendarIsNew || calendarIsChecked
      }
    }
  }

  _loadCalendarList () {
    return JSON.parse(localStorage.getItem(this._storageName()))
  }

  _saveCalendarList () {
    const selectedCalendars = this.calendarTargets.reduce((calendars, target) => {
      calendars[target.dataset.id] = target.checked
      return calendars
    }, {})

    localStorage.setItem(this._storageName(), JSON.stringify(selectedCalendars))
  }

  _storageName () {
    return 'department-calendars' + this.data.get('department-id')
  }

  print () {
    const ids = this.eventSources.map(event => event.id).toString()
    const start = Date.parse(this.calendar.view.currentStart)
    const end = Date.parse(this.calendar.view.currentEnd)
    window.location.href = `calendars/print?calendar_ids=${ids}&start=${start}&end=${end}`
  }
}

function selectCallback (info) {
  const hasTime = function (date) {
    return date.getHours() !== 0 && date.getMinutes !== 0
  }
  const start = info.start
  const end = info.end
  const allDay = !hasTime(start) && !hasTime(end)
  const isSpanningMultipleDays = start !== end
  // end is the day after the selection, if a selection is made in the month view.
  // we want the end day to be the previous day in this scenario.
  // this only applies if the selection is multiple days.
  let calculatedEnd = end
  if (allDay && isSpanningMultipleDays) {
    const newDate = new Date(end.getTime())
    newDate.setDate(newDate.getDate() - 1)
    calculatedEnd = newDate
  }

  Turbo.visit('/events/new?start=' + start.toISOString() + '&end=' + calculatedEnd.toISOString() + '&allday=' + allDay)
};

function defaultDate () {
  // This was found here: http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
  const getParameterByName = function (name) {
    const url = window.location.href
    // The escape character \[ is actullay optional inside a group, but [[\]] just looks weird
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  const year = getParameterByName('year')
  const month = getParameterByName('month')
  if (year && month) {
    return new Date(year, parseInt(month) - 1, 1)
  } else {
    return new Date()
  }
}
