import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'row', 'day', 'startDate']

  connect () {
    this.weekdays = JSON.parse(this.data.get('weekdays'))
    this.updateRows()
  }

  updateRows () {
    const repeatInterval = parseInt(this.selectTarget.value)

    this.resetRows()
    this.rowTargets.forEach((row, i) => {
      if (i < repeatInterval) {
        row.classList.remove('hidden')
      } else {
        row.classList.add('hidden')
      }
    })

    const startDate = this.startDateTarget.value
    if (startDate) {
      const [day, month, year] = startDate.split('-').map(x => parseInt(x))

      // Give labels to the rows
      this.dayTargets.forEach((dayTarget, i) => {
        const date = new Date(year, month - 1, day + i)

        if (repeatInterval === 7) {
          dayTarget.innerHTML = this.dayName(date.getDay())
        } else {
          dayTarget.innerHTML = i + 1
        }
      })

      // If the iteration interval is a week, we want to show the schema sorted as Monday, Tuesday, Wednesday ... Sunday
      if (repeatInterval === 7) {
        const table = this.rowTargets[0].parentElement
        const rows = this.rowTargets.slice(0, 7)
        rows.forEach(row => row.remove())
        const sortedRows = rows.sort((a, b) => {
          const aDay = parseInt(a.dataset.day)
          const bDay = parseInt(b.dataset.day)
          const aDate = new Date(year, month - 1, day + aDay - 1)
          const bDate = new Date(year, month - 1, day + bDay - 1)

          // Date.prototype.getDay() return sunday as 0, convert so monday is 0 and sunday is 6
          const aWeekday = (aDate.getDay() + 7) % 8
          const bWeekday = (bDate.getDay() + 7) % 8

          if (aWeekday < bWeekday) {
            return -1
          } else if (aWeekday > bWeekday) {
            return 1
          } else {
            return 0
          }
        })

        sortedRows.forEach(row => table.append(row))
      }
    }
  }

  dayName (number) {
    return this.weekdays[number]
  }

  // Resets the days to be sorted by the day number
  resetRows () {
    const table = this.rowTargets[0].parentElement
    const rows = this.rowTargets
    rows.forEach(row => row.remove())
    const sortedRows = rows.sort((a, b) => {
      const aDay = parseInt(a.dataset.day)
      const bDay = parseInt(b.dataset.day)

      if (aDay < bDay) {
        return -1
      } else if (aDay > bDay) {
        return 1
      } else {
        return 0
      }
    })

    sortedRows.forEach(row => table.append(row))
  }
}
