import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = ['personList', 'chosenPersonId', 'nameField', 'popUp']

  initialize () {
    this.queryPerson = debounce(this.queryPerson, 200)
  }

  queryPerson (event) {
    const term = event.target.value
    $.get(this.data.get('url'), { search_term: term, redirect_to: window.location.pathname }, (data) => { this.personListTarget.innerHTML = data })
  }

  setChosen (event) {
    this.chosenPersonIdTarget.value = event.target.dataset.id
    this.nameFieldTarget.value = event.target.innerHTML
    this.popUpTarget.classList.add('hidden')
  }
}
