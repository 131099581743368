import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['file', 'name']

  updateName () {
    if (this.nameIsEmpty) {
      const name = this.fileTarget.value.split('\\').pop()
      this.nameTarget.value = name
    }
  }

  get nameIsEmpty () {
    return this.nameTarget.value === ''
  }
}
