import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'submit']

  initialize () {
    this.autoSave = throttle(this.autoSave, 5000)
    this.debouncedSubmitFormToTurboFrame = debounce(this.submitFormToTurboFrame, 200).bind(this)
  }

  connect () {
    this.defaultTarget = this.element.getAttribute('target')
  }

  update (event) {
    if (this.disabledFor(event)) return

    if (this.shouldMorphInstantly(event)) {
      this.submitFormToTurboFrame(event)
    } else if (this.shouldMorphDebounced(event)) {
      this.debouncedSubmitFormToTurboFrame(event)
    } else {
      this.autoSave()
    }
  }

  ensureSubmitTarget () {
    if (!this.hasSubmitTarget) {
      this.insertSubmitTarget()
    }
  }

  submitFormToTurboFrame (event) {
    this.ensureSubmitTarget()
    this.element.setAttribute('target', '_self')
    this.formTarget.requestSubmit(this.submitTarget)
    this.element.setAttribute('target', this.defaultTarget)
  }

  async autoSave () {
    const data = new FormData(this.formTarget)
    data.set('dynamic_form', true)
    const url = this.formTarget.action
    const httpMethod = data.get('_method') || this.formTarget.method
    const request = new FetchRequest(httpMethod, url, { body: data })
    await request.perform()
  }

  shouldMorphInstantly (event) {
    const isRadio = event.target instanceof window.HTMLInputElement && event.target.type === 'radio'
    const isSelect = event.target instanceof window.HTMLSelectElement
    const isCheckbox = event.target instanceof window.HTMLInputElement && event.target.type === 'checkbox'

    return isRadio || isSelect || isCheckbox
  }

  shouldMorphDebounced (event) {
    const isTextInput = event.target instanceof window.HTMLInputElement && event.target.type === 'text'
    const isTextArea = event.target instanceof window.HTMLTextAreaElement

    return isTextInput || isTextArea
  }

  insertSubmitTarget () {
    const submitter = document.createElement('input')
    submitter.classList.add('hidden')
    submitter.type = 'submit'
    submitter.name = 'dynamic_form'
    submitter.value = 'true'
    submitter.dataset.dynamicFormTarget = 'submit'
    this.formTarget.appendChild(submitter)
  }

  disabledFor (event) {
    return event.target.dataset.disableDynamic === 'true'
  }
}
