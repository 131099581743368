import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  filter (e) {
    e.preventDefault()
    const showPrivate = this.element.dataset.showPrivate
    $('#fmk_drug_medications_filter_show_private').val(showPrivate)
    $('#fmk_drug_medications_filter_show_private').parents('form').submit()
  }
}
