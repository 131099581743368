import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'content'
  ]

  connect () {
    this.contentTargets.forEach(function (bulletin) {
      $(bulletin).find('a').attr('target', '_blank')
    })
  }
}
