import { StreamActions } from '@hotwired/turbo'
import { closeModal } from './modal_events'

StreamActions.close_modal = function () {
  closeModal()
}

StreamActions.dispatch_event = function () {
  const target = this.getAttribute('target')
  const name = this.getAttribute('name')
  const element = document.getElementById(target)
  element.dispatchEvent(new CustomEvent(name, { bubbles: true }))
}

StreamActions.show_remote_modal = function () {
  const container = document.querySelector('remote-modal-container')
  container.replaceChildren(this.templateContent)
  container.querySelector('dialog').showModal()
}
