import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'weeklySelect',
    'monthlySelect',
    'day',
    'date',
    'dateSelect',
    'daysSelect',
    'weeklyOrMonthlySelect',
    'form',
    'recurringCheckbox',
    'limitedOccurrencesCheckbox',
    'occurrencesInput'
  ]

  connect () {
    setTimeout(() => {
      this.days = this.getSelectedOptions(this.daysSelectTarget)
      this.dates = this.getSelectedOptions(this.dateSelectTarget)
      this.colorDaysAndMonths()
    }, 50)
    this.onSelectWeeklyMonthly()
    this.toggleForm()
    this.toggleOccurrence()

    this.days = []
    this.dates = []
  }

  toggleOccurrence () {
    if (this.limitedOccurrencesCheckboxTarget.checked) {
      this.occurrencesInputTarget.classList.remove('hidden')
    } else {
      this.occurrencesInputTarget.classList.add('hidden')
    }
  }

  toggleForm () {
    if (this.recurringCheckboxTarget.checked) {
      this.formTarget.classList.remove('hidden')
    } else {
      this.formTarget.classList.add('hidden')
    }
  }

  onSelectWeeklyMonthly () {
    const weeklyOrMonthly = this.weeklyOrMonthlySelectTarget.value

    if (weeklyOrMonthly === 'WEEKLY') {
      this.weeklySelectTarget.classList.remove('hidden')
      this.monthlySelectTarget.classList.add('hidden')
    } else if (weeklyOrMonthly === 'MONTHLY') {
      this.weeklySelectTarget.classList.add('hidden')
      this.monthlySelectTarget.classList.remove('hidden')
    }
  }

  selectDate (event) {
    if (this.dates.includes(event.srcElement.dataset.date)) {
      const index = this.dates.indexOf(event.srcElement.dataset.date)
      this.dates.splice(index, 1)
    } else {
      this.dates.push(event.srcElement.dataset.date)
    }

    this.setSelectedOptions(this.dateSelectTarget, this.dates)
    this.colorDaysAndMonths()
  }

  selectDay (event) {
    if (this.days.includes(event.srcElement.dataset.day)) {
      const index = this.days.indexOf(event.srcElement.dataset.day)
      this.days.splice(index, 1)
    } else {
      this.days.push(event.srcElement.dataset.day)
    }

    this.setSelectedOptions(this.daysSelectTarget, this.days)

    this.colorDaysAndMonths()
  }

  setSelectedOptions (select, options) {
    for (const option of select.options) {
      if (options.includes(option.value)) {
        option.selected = true
      } else {
        option.selected = false
      }
    }
  }

  getSelectedOptions (select) {
    return Array.from(select.options)
      .filter(option => option.selected)
      .map(option => option.value)
  }

  colorDaysAndMonths () {
    [
      ...this.dayTargets.filter(item => this.days.includes(item.dataset.day)),
      ...this.dateTargets.filter(item => this.dates.includes(item.dataset.date))
    ].forEach(item => {
      item.classList.add('bg-blue-600')
      item.classList.remove('bg-blue-200')
      item.classList.add('text-white')
    });

    [
      ...this.dayTargets.filter(item => !this.days.includes(item.dataset.day)),
      ...this.dateTargets.filter(item => !this.dates.includes(item.dataset.date))
    ].forEach(item => {
      item.classList.remove('bg-blue-600')
      item.classList.add('bg-blue-200')
      item.classList.remove('text-white')
    })
  }
}
