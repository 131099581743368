import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'row']

  connect () {
    // Set first row as active
    this.setActiveRow(this.rowTargets[0])
  }

  replace (e) {
    this.setActiveRow(e.currentTarget)

    $.get(e.currentTarget.dataset.url, (response) => {
      this.containerTarget.innerHTML = response
    })
  }

  setActiveRow (el) {
    this.rowTargets.forEach(row => {
      row.classList.remove('active-row')
    })

    if (el) el.classList.add('active-row')
  }
}
