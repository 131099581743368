export default {
  da: {
    attachFiles: 'Vedhæft fil',
    bold: 'Fed',
    bullets: 'Punkttegn',
    byte: 'Byte',
    bytes: 'Bytes',
    captionPlaceholder: 'Tilføj billedtekst…',
    code: 'Kode',
    heading1: 'Overskrift',
    indent: 'Indryk',
    italic: 'Italic',
    link: 'Link',
    numbers: 'Tal',
    outdent: 'Fjern indryk',
    quote: 'Citat',
    redo: 'Annuller fortryd',
    remove: 'Fjern',
    strike: 'Gennemstreget',
    undo: 'Fortryd',
    unlink: 'Unlink',
    urlPlaceholder: 'Indtast link…',
    GB: 'GB',
    KB: 'KB',
    MB: 'MB',
    PB: 'PB',
    TB: 'TB'
  },
  nb: {
    bold: 'Fet',
    bullets: 'Punkt',
    byte: 'Byte',
    bytes: 'Bytes',
    captionPlaceholder: 'Legg til bildetekst…',
    code: 'Kode',
    heading1: 'Overskrift',
    indent: 'Innrykk',
    italic: 'Kursiv',
    link: 'Lenke',
    numbers: 'Tall',
    outdent: 'Fjern innrykk',
    quote: 'Sitat',
    redo: 'Annuller angre',
    remove: 'Fjern',
    strike: 'Gjennomstreket',
    undo: 'Angre',
    unlink: 'Unlink',
    urlPlaceholder: 'Tast inn lenke…',
    GB: 'GB',
    KB: 'KB',
    MB: 'MB',
    PB: 'PB',
    TB: 'TB'
  }
}
