import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message']

  submit () {
    document.dispatchEvent(new CustomEvent('save-deviations-form:submit', {
      bubbles: true,
      cancelable: true,
      detail: { message: this.messageTarget.value }
    }))
  }
}
